export const initGA = (measurementId) => {
    if (!window.gtag) {
        console.error('Google Analytics is not initialized');
        return;
    }
    window.gtag('config', measurementId, {
        page_path: window.location.pathname,
    });
};

export const logEvent = (action, category, label, value) => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        });
    }
};
