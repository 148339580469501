import React, { useEffect } from 'react';
import './App.css';
import ComponentsRoutes from './Components/Routes/ComponentsRoutes';
import { useLocation } from 'react-router-dom';
import { initGA } from './utiles/analytics';

const App = () => {    
    const AnalyticsTracker = () => {
      const location = useLocation();
  
      useEffect(() => {
          initGA('G-G8S37BB3EN'); // Replace with your Measurement ID
          window.gtag('event', 'page_view', { page_path: location.pathname });
      }, [location]);
  
      return null;
  };

  return (
    <div>
      <AnalyticsTracker />
      <ComponentsRoutes />
    </div>
  );
}

export default App;
